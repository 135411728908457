/* eslint-disable @typescript-eslint/no-unused-vars */
// import { RadioGroup } from '@headlessui/react';
// import { StarIcon } from '@chakra-ui/icons';
// import { Box, Icon, Badge, Text } from '@chakra-ui/react';
// import { HomeIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Flex,
  Text,
  Badge,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  XIcon,
  UserIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import _, { filter } from 'lodash';
import memoize from 'memoize-one';
import moment from 'moment';
import React, { createRef, forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { FixedSizeList as List, areEqual } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { Link, Spinner } from '@/components/Elements';
import { PatientListCard } from '@/components/Elements/PatientListCard/PatientListCardBlue';
import { usePatientStore } from '@/stores/patients';

import { usePatients } from '../api/getPatients';
import { Patient } from '../types';

import { AddPatientModal } from './BV/AddPatientModal';
import { useClinicDevices } from '../api/getClinicDevices';

// import { Patient } from '../types';

export const PatientsList = () => {
  // const { clinic_user_id } = useParams();

  const { data, isLoading } = usePatients();

  const {
    patients,
    setPatients,
    setSelectedPatientId,
    selectedPatientId,
    sortBy,
    sortByLabel,
    sortDirection,
    setSortBy,
    toggleSortDirection,
  } = usePatientStore();

  // const [selectedPatient, setSelectedPatient] = useState<number | null>(null);

  const [filterString, setFilterString] = useState<string>('');

  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);

  // setSelectedPatientId(clinic_user_id ? parseInt(clinic_user_id) : 0);

  const searchFor: (patient: Patient, filter: string) => boolean = (patient, searchTerm) => {
    searchTerm = searchTerm.toLowerCase().replaceAll('#', '');

    return (
      !searchTerm ||
      patient.user_name.toLowerCase().includes(searchTerm) ||
      patient.clinic_user_id.toString().includes(searchTerm) ||
      patient.clinic_user_id === selectedPatientId ||
      moment
        .unix(+patient.dob)
        .format('Y-MM-DD')
        .includes(searchTerm)
    );
  };

  // useEffect(() => {}, [selectedPatientId]);

  useEffect(() => {
    if (data) setPatients(data);
  }, [data, setPatients]);

  const filteredPatients = patients.filter((p) => {
    return p.is_active === '1' && searchFor(p, filterString);
  });

  const sortedPatients = useMemo(() => {
    return filteredPatients.sort((a, b) => {
      if (sortBy === 'user_name') {
        return sortDirection === 'asc' ? a.user_name.localeCompare(b.user_name) : b.user_name.localeCompare(a.user_name);
      } else if (sortBy === 'clinic_user_id') {
        return sortDirection === 'asc' ? a.clinic_user_id - b.clinic_user_id : b.clinic_user_id - a.clinic_user_id;
      } else if (sortBy === 'medical_record_num') {
        return sortDirection === 'asc' ? a.medical_record_num.localeCompare(b.medical_record_num) : b.medical_record_num.localeCompare(a.medical_record_num);
      } else if (sortBy === 'timestamp') {
        return sortDirection === 'asc' ? parseInt(a.timestamp) - parseInt(b.timestamp) : parseInt(b.timestamp) - parseInt(a.timestamp);
      } else if (sortBy === 'last_played') {
        return sortDirection === 'asc' ? parseInt(a.last_played) - parseInt(b.last_played) : parseInt(b.last_played) - parseInt(a.last_played);
      } else {
        return 0;
      }
    })
  }, [filteredPatients, sortBy, sortDirection]);

  const Row = ({ index, style }: any) => {
    return (
      <div style={{ ...style, paddingRight: '1rem', paddingLeft: '1rem' }}>
        <Link to={`/app/patients/${sortedPatients[index].clinic_user_id}/configuration`}>
          <PatientListCard
            key={sortedPatients[index].clinic_user_id}
            patient={sortedPatients[index]}
            clickHandler={() => {
              setSelectedPatientId(sortedPatients[index].clinic_user_id);
            }}
          // isSelected={selectedPatientId === filteredPatients[index].clinic_user_id ? true : false}
          ></PatientListCard>
        </Link>
      </div>
    );
  }


  if (isLoading) {
    return (
      <Box w="full" h="full" bg="gray.100" boxShadow="sm">
        <Box pt={2} pb={2} px={4} className="h-full">
          <Input
            rounded="md"
            border="none"
            background="gray.400"
            fontWeight="medium"
            color="blue.50"
            readOnly
          />
          <div className="w-full h-full bg-white rounded-md my-4 flex justify-center items-center">
            <Spinner size="lg" />
          </div>
        </Box>
      </Box>
    );
  }



  if (!data) return null;

  // if (clinic_user_id) {
  //   console.log('selectedPatientId', parseInt(clinic_user_id));
  //   setSelectedPatientId(parseInt(clinic_user_id));
  // }





  // const createItemData = memoize((items, toggleItemActive) => ({
  //   filteredPatients,
  //   toggleItemActive,
  // }));

  const toggleItemActive = (index: number) => {
    setSelectedPatientId(filteredPatients[index].clinic_user_id);
  };
  // const itemData = createItemData(filteredPatients, toggleItemActive);


  const listRef = React.createRef<any>();

  const handleScroll = ({ target }: any) => {
    const { scrollTop } = target;

    listRef.current.scrollTo(scrollTop);
  };

  const vertTrackWidthChosen = '10px'; // Original is 6px

  return (
    <>
      <Box w="full" h="full" bg="gray.100" boxShadow="sm">
        <Box pt={2} pb={2} px={4}>
          <InputGroup size="md">
            <InputLeftElement pointerEvents="none" color="blue.50" fontSize="1.2em">
              <Icon as={UserIcon} w={6} h={6} color="blue.50" />
            </InputLeftElement>
            <Input
              rounded="md"
              border="none"
              background="gray.400"
              fontWeight="medium"
              color="blue.50"
              _placeholder={{ color: 'blue.50' }}
              placeholder="Filter (Name, ID, DOB)"
              onChange={(e) => setFilterString(e.target.value)}
              value={filterString}
            />
            <InputRightElement>
              {filterString ? (
                <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded bg-blue-500 h-5 w-5">
                  <XIcon
                    onClick={() => {
                      setFilterString('');
                    }}
                    cursor="pointer"
                    className="h-4 w-4 text-blue-50"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                // <Badge
                //   variant="outline"
                //   cursor="pointer"
                //   colorScheme="blue"
                //   mr="12"
                //   size="xs"
                //   onClick={() => {
                //     setFilterString('');
                //   }}
                //   textTransform="lowercase"
                // >
                //   Clear Filter
                // </Badge>
                ''
              )}
            </InputRightElement>
          </InputGroup>
        </Box>
        <Flex px={4} pb={1}>
          <Text fontSize="xs" color="gray" alignSelf="center">
            <chakra.span fontWeight="semibold">{patients.length}</chakra.span> Participants
          </Text>
          <Spacer />
          <Flex>
            <Text fontSize="xs" color="gray" alignSelf="center">
              Sorted by
            </Text>

            <Menu>
              <MenuButton
                fontSize={['0.7rem']}
                py={0}
                size="xs"
                fontWeight="semibold"
                as={Button}
                color="gray.500"
                // bg="gray.200"
                colorScheme="gray"
                variant="outline"
                ml={1}
              >
                {sortByLabel()} <Icon as={ChevronDownIcon} />
              </MenuButton>
              <MenuList fontSize="xs" fontWeight="bold" minWidth="140px">
                <MenuItem onClick={() => setSortBy('last_played')}>Last Played</MenuItem>
                <MenuItem onClick={() => setSortBy('timestamp')}>Added Date</MenuItem>
                <MenuItem onClick={() => setSortBy('user_name')}>Username</MenuItem>
                <MenuItem onClick={() => setSortBy('clinic_user_id')}>Participant ID</MenuItem>
                <MenuItem onClick={() => setSortBy('medical_record_num')}>Custom ID</MenuItem>
              </MenuList>
            </Menu>
            <Button
              fontSize={['0.7rem']}
              py={0}
              size="xs"
              fontWeight="bold"
              as={Button}
              colorScheme="gray"
              variant="outline"
              // color="gray.500"
              // bg="gray.200"
              ml={1}
              onClick={() => toggleSortDirection()}
            >
              {sortDirection == 'desc' ? <Icon as={ArrowDownIcon} /> : <Icon as={ArrowUpIcon} />}
            </Button>
          </Flex>
        </Flex>
        <AutoSizer style={{ height: '100%', width: '100%' }}>
          {({ height, width }) => (
            <Box px={0} className="max-h-full h-10">
              <Scrollbars
                autoHeight={true}
                autoHeightMax={height}
                onScroll={handleScroll}
                renderTrackVertical={({ style, ...props }) => {
                  return (
                    <div
                      {...props}
                      style={{
                        ...style,
                        width: vertTrackWidthChosen,
                        right: '2px',
                        bottom: '2px',
                        top: '2px',
                        borderRadius: '5px',
                      }}
                    />
                  );
                }}
              >
                <List
                  ref={listRef}
                  className="List"
                  height={height - 140}
                  // itemData={filteredPatients}
                  itemCount={filteredPatients.length}
                  itemSize={110}
                  width={width}
                  style={{ overflow: 'false' }}
                >
                  {Row}
                </List>
              </Scrollbars>
              <Flex pt={2} pb={2} px={4}>
                <Button colorScheme="blue" w="full" onClick={() => setAddPatientModalOpen(true)}>
                  Add Participant
                </Button>
              </Flex>
            </Box>
          )}
        </AutoSizer>
      </Box>
      <AddPatientModal
        isOpen={addPatientModalOpen}
        // setOpen={setAddPatientModalOpen}
        onClose={() => setAddPatientModalOpen(false)}
      ></AddPatientModal>
    </>
  );
};
