// import { AxiosResponse } from 'axios';

import { report } from 'process';

import md5 from 'md5';

import { axios } from '@/lib/axios';

import { LoginAPIResponse } from '../types';

export type LoginCredentialsDTO = {
  u: string;
  p: string;
  sub_user?: string;
  is_hashed?: boolean;
};

export const loginWithUsernameAndPassword = ({
  is_hashed = false,
  ...data
}: LoginCredentialsDTO): Promise<LoginAPIResponse> => {
  return axios
    .post<LoginAPIResponse>('/vue_api/log_in', {
      u: data.u,
      p: is_hashed ? data.p : md5(data.p),
      sub_user: data.sub_user,
    })
    .then((resp: any) => {
      console.log('loginWithUsernameAndPassword::resp', resp);
      if (resp.error) {
        return Promise.reject(resp);
      }
      return resp;
    })
    .catch((err: any) => {
      console.error('loginWithUsernameAndPassword::err', err);
      return Promise.reject(err);
    });
};
