import _ from 'lodash';
import create from 'zustand';
import { persist } from 'zustand/middleware';

// eslint-disable-next-line no-restricted-imports
import { Patient } from '@/features/patients/types';

type sortByTypes =
  | 'last_played'
  | 'user_name'
  | 'medical_record_num'
  | 'clinic_user_id'
  | 'timestamp';

type PatientsStore = {
  patients: Patient[];
  setPatients: (p: Patient[]) => void;
  selectedPatient: Patient | null | undefined;
  selectedPatientId: number;
  setSelectedPatientId: (id: number) => void;
  setSelectedPatient: (p: Patient) => void;
  sortBy: sortByTypes;
  sortDirection: 'asc' | 'desc';
  sortByLabel: () => string;
  setSortBy: (s: sortByTypes) => void;
  toggleSortDirection: () => void;
  // archivePatient: (id: string) => void;
  // unArchivePatient: (id: string) => void;
};

// const getLocalStorage: any = (key: string) => window.localStorage.getItem(key);
// const setLocalStorage = (key: string, value: any) =>
//   window.localStorage.setItem(key, JSON.stringify(value));

export const usePatientStore = create<PatientsStore>(
  // persist(
  (set, get) => ({
    patients: [],
    selectedPatient: null,
    setPatients: (ps: Patient[]) => {
      set(() => ({
        patients: ps,
      }));
      console.log('setPatients', get().selectedPatientId);
      if (get().selectedPatientId != 0) {
        get().setSelectedPatientId(get().selectedPatientId);
      }
      // get().setSortBy('user_name');
    },
    selectedPatientId: 0,
    setSelectedPatientId: (id: number) => {
      set((store) => ({
        selectedPatient: store.patients.find((p) => {
          return p.clinic_user_id == id;
        }),
        selectedPatientId: id,
      }));
    },
    setSelectedPatient: (p: Patient) =>
      set(() => ({
        selectedPatient: p,
        selectedPatientId: p.clinic_user_id,
      })),
    sortBy: 'user_name',
    sortByLabel: () => {
      if (get().sortBy === 'user_name') return 'Username';
      if (get().sortBy === 'last_played') return 'Last Played';
      if (get().sortBy === 'medical_record_num') return 'Custom ID';
      if (get().sortBy === 'clinic_user_id') return 'Patient ID';
      if (get().sortBy === 'timestamp') return 'Date Added';
      else return '';
    },
    sortDirection: 'asc',
    setSortBy: (s: sortByTypes) => {
      // setLocalStorage('sortBy', s);
      set(() => ({
        sortBy: s,
        patients: _.orderBy(get().patients, [s], [get().sortDirection]),
      }));
    },
    toggleSortDirection: () => {
      const dir = get().sortDirection == 'desc' ? 'asc' : 'desc';
      // setLocalStorage('sortDirection', dir);
      set(() => ({
        sortDirection: dir,
        patients: _.orderBy(get().patients, [get().sortBy], [dir]),
      }));
    },
  })
  // {
  //   name: 'patients-storage',
  //   partialize: (state) =>
  //     Object.fromEntries(
  //       Object.entries(state).filter(([key]) => !['selectedPatientId'].includes(key))
  //     ),
  // }
  // )
);
