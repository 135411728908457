import { Alert, AlertTitle, AlertDescription, Button } from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as z from 'zod';


import { Form, InputField, SelectField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

import { updateNamedExports } from 'typescript';
import { type } from 'os';
import md5 from 'md5';
import { usePharma } from '@/providers/PharmaProvider';

const schema = z.object({
  u: z.string().min(1, 'Required'),
  p: z.string().min(1, 'Required'),
  sub_user: z.string().optional(),
});

type LoginValues = {
  u: string;
  p: string;
  sub_user?: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const PVForm = () => {
  const { logout } = useAuth();
  const { pharmaLogout, user, isPV } = usePharma();
  const navigate = useNavigate();


  if (!isPV)
    return <Navigate to="/" />

  return (
    <div>

      <Link to="/devices" className="font-medium text-blue-600 hover:text-blue-500 w-full">
        <Button w="full" size="lg" colorScheme="blue">
          Manage Devices
        </Button>
      </Link>
      <div className="text-xs text-slate-500 mt-8 text-center">
        Currently logged in as {user?.user_name} as a PV user
      </div>
      <Button w="full" size="lg" colorScheme="blue" variant="ghost" onClick={async () => {
        await logout().then(() => {
          pharmaLogout();
        });

        navigate('/');
      }}>
        Logout
      </Button>
    </div>
  );
};
