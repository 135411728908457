import { Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

import { updateNamedExports } from 'typescript';
import { type } from 'os';
import md5 from 'md5';
import { usePharma } from '@/providers/PharmaProvider';
import { UserInfo } from '../types';

const schema = z.object({
  u: z.string().min(1, 'Required'),
  p: z.string().min(1, 'Required'),
  sub_user: z.string().optional(),
});

type LoginValues = {
  u: string;
  p: string;
  sub_user?: string;
};

type LoginFormProps = {
  onSuccess: (values: UserInfo) => void;
};

const PharmaUserFlags = ["PHARMA1_CRC", "PHARMA1_PV"];

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();
  const { user, isSiteLoggedIn, isCRC, isPV, setPlainP } = usePharma();
  const [error, setError] = useState(null);

  // const [subUsers, setSubUsers] = useState<{ user_id: number; user_name: string }[]>([]);

  if (isCRC)
    return <Navigate to="/auth/crc" />

  if (isPV)
    return <Navigate to="/auth/pv" />

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          console.log("values", values);
          setPlainP(values.p);
          login(values).then((login_data) => {
            console.log("login_data", login_data);
            if (login_data) {

              onSuccess(login_data);
            }
          }).catch((err) => {
            setError(err);
          });
        }}
        className="w-full"
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            {error && (
              <Alert
                status="error"
                className="text-center"
                rounded="md"
                textAlign="center"
                display="block"
              >
                <AlertTitle mr={2}>Oops!</AlertTitle>
                <AlertDescription>Invalid Username or Password.</AlertDescription>
              </Alert>
            )}
            {/* {subUsers.length === 0 ? ( */}
            <>
              <InputField
                size="lg"
                type="text"
                label="User Name"
                error={formState.errors['u']}
                registration={register('u')}
              />
              <InputField
                size="lg"
                type="password"
                label="Password"
                error={formState.errors['p']}
                registration={register('p')}

              />
            </>
            {/* ) : (
              <SelectField
                size="lg"
                options={subUsers.map((subUser) => ({
                  label: subUser.user_name,
                  value: subUser.user_id,
                }))}
                label="Select a Site"
                registration={register('sub_user')}
              ></SelectField>
            )} */}
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full mt-3" size="lg">
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>
      {/* {subUsers.length !== 0 && (
        <Link to="/devices" className="font-medium text-blue-600 hover:text-blue-500 w-full">
          <Button className="w-full mt-3" size="lg">
            Manage Devices
          </Button>
        </Link>
      )} */}
      {/* {subUsers.length === 0 && ( */}
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          {/* <a
            href="https://www.seevividly.com/reset_password"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-blue-600 hover:text-blue-500"
          > */}
          Forgot password? Please contact us.
          {/* </a> */}
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
