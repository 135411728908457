import { Dashboard } from '@/features/misc';
import { Route, Routes } from 'react-router-dom';
import { CRCForm } from '../components/CRCForm';
import { CRCSelect } from './CRCSelect';

import { Login } from './Login';
import { PVSelect } from './PVSelect';
import { Register } from './Register';

export const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="register" element={<Register />} /> */}
      <Route path="login" element={<Login />} />
      <Route path="crc" element={<CRCSelect />} />
      <Route path="pv" element={<PVSelect />} />
    </Routes>
  );
};
