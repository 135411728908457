import { useAuth } from '@/lib/auth';
import { usePharma } from '@/providers/PharmaProvider';
import { use } from 'marked';
import md5 from 'md5';
import { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { CRCForm } from '../components/CRCForm';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';
import { UserInfo } from '../types';

export const Login = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  const { setUser, setSubUsers, setFlags, setIsCRC, setIsPV, isCRC, isPV } = usePharma();

  const pharma = usePharma();

  console.log('pharma', pharma);

  const handleSuccess = (login_data: UserInfo) => {
    console.log("login_data", login_data);

    window.sessionStorage.setItem('u', login_data.user_name);
    window.sessionStorage.setItem('p', md5(login_data.p));

    const user = login_data;

    if (!user) {
      return;
    }

    // route to /app by default 
    let route = '/app';

    setUser(user);

    const userFlags = user.flags || [];
    const userIsCRC = userFlags.includes("PHARMA1_CRC");
    const userIsPV = userFlags.includes("PHARMA1_PV");
    const userSubUsers = user.sub_users || [];

    // check if Pharma flags are present
    setFlags(userFlags);
    setIsCRC(userIsCRC);
    setIsPV(userIsPV);
    setSubUsers(userSubUsers);


    console.log("login_data", login_data);

    if (userIsCRC)
      route = '/auth/crc';

    if (userIsPV)
      route = '/auth/pv';

    navigate(route);
  }

  // if (auth.user)
  //   return <Navigate to="/app" />

  // if (isCRC)
  //   return <Navigate to="/auth/crc" />

  // if (isPV)
  //   return <Navigate to="/auth/pv" />

  // useEffect(() => {
  //   if (auth.user)
  //     navigate('/app');

  //   if (isCRC)
  //     navigate('/auth/crc');

  //   if (isPV)
  //     navigate('/auth/pv');


  // }, []);

  return (
    <Layout title="Log In | Vivid Vision Perimetry">
      <LoginForm onSuccess={handleSuccess} />
    </Layout>
  );
};
