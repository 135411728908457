import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
// import { TFjs } from '@/features/tfjs/components/TFjs';

const { DiscussionsRoutes } = lazyImport(
  () => import('@/features/discussions'),
  'DiscussionsRoutes'
);
const { PatientsRoutes } = lazyImport(() => import('@/features/patients'), 'PatientsRoutes');
const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { DevicesRoutes } = lazyImport(() => import('@/features/devices'), 'DevicesRoutes');
const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/discussions/*', element: <DiscussionsRoutes /> },
      { path: '/users', element: <Users /> },
      { path: '/patients/*', element: <PatientsRoutes /> },
      { path: '/profile', element: <Profile /> },
      // { path: '/tfjs', element: <TFjs /> },
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="/app" /> },
    ],
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  { path: '/devices/*', element: <DevicesRoutes />, children: [] },
];
