/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from '@chakra-ui/icons';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  UserIcon,
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  MenuIcon,
  BellIcon,
  UsersIcon,
  XIcon,
  KeyIcon,
  UserGroupIcon,
  AdjustmentsIcon,
  CreditCardIcon,
  CollectionIcon,
} from '@heroicons/react/solid';

import VRHeadsetIcon from '@iconify/icons-teenyicons/vr-headset-solid';
import { Icon as IconifyIcon } from '@iconify/react/dist/offline';

// import { MenuIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import * as React from 'react';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/order
import {
  Box,
  Text,
  Button,
  Tooltip,
  IconButton,
  Spacer,
  Heading,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import Moment from 'react-moment';
import { NavLink, Link } from 'react-router-dom';
// import useScript from 'react-script-hook';

import logo from '@/assets/vivid_vision_logo.png';
import { useVVNotifications } from '@/features/notifications/api/getVVNotifications';
import { VVNotificationModal } from '@/features/notifications/api/NotificationModal';
import { VVNotification } from '@/features/notifications/types';
import { PatientsList } from '@/features/patients/components/PatientsListBlue';
import { useAuth } from '@/lib/auth';
import { useAuthorization, ROLES } from '@/lib/authorization';
import { Head } from '../Head';
import { axios } from '@/lib/axios';
import { ManageHeadsetsModal } from '@/features/patients/components/ManageHeadsetsModal';
import useScript from 'react-script-hook';
import { usePatientStore } from '@/stores/patients';
import { usePharma } from '@/providers/PharmaProvider';
import { is } from 'date-fns/locale';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  disabled?: boolean;
  tooltip: string;
};

const SideNavigation = () => {
  const { checkAccess } = useAuthorization();

  const { selectedPatientId } = usePatientStore();

  const navigation = [
    { name: 'Home', to: '.', icon: HomeIcon, tooltip: 'Home' },
    // { name: 'Patients', to: './patients', icon: AdjustmentsIcon, tooltip: 'Patient Settings', disabled: selectedPatientId == 0 },
    checkAccess({ allowedRoles: [ROLES.is_admin, ROLES.is_mod] }) && {
      name: 'Mod',
      to: './mod_tools',
      icon: KeyIcon,
    },
  ].filter(Boolean) as SideNavigationItem[];

  return (
    <>
      {navigation.map((item, index) => (

        <Tooltip key={item.name} label={item.tooltip} fontSize="md">
          {item.disabled ? (
            <div
              className={clsx(
                'text-blue-200 group flex items-center px-3 py-3 text-base font-normal rounded-md opacity-30'
              )}
            >
              <item.icon className={clsx('', 'flex-shrink-0 h-6 w-6')} aria-hidden="true" />
            </div>
          ) : (
            <NavLink
              end={index === 0}
              key={item.name}
              to={item.to}
              className={clsx(
                'text-blue-200 active:text-white active:bg-blue-900 hover:bg-blue-700 hover:text-white',
                'group flex items-center px-3 py-3 text-base font-normal rounded-md'
              )}
              activeClassName="text-blue-700 bg-white active:text-white hover:text-white hover:bg-blue-700"
            >
              <item.icon className={clsx('', 'flex-shrink-0 h-6 w-6')} aria-hidden="true" />
            </NavLink>
          )}
        </Tooltip>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { isCRC, isPV, isSiteLoggedIn, setIsSiteLoggedIn } = usePharma();
  const { logout } = useAuth();
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<VVNotification | null>(null);

  const { data: notificationsData, isLoading: notificationsLoading } = useVVNotifications({
    clinic_user_id: 0,
  });

  const userNavigation = [
    // { name: 'My Account', to: './profile' },
    {
      name: isCRC ? 'Back to Site Selection' : 'Logout',
      to: '',
      onClick: () => {
        logout().then(() => {
          setIsSiteLoggedIn(false);
          let route = '/';

          if (isCRC) {
            route = '/auth/crc';
          }

          if (isPV) {
            route = '/auth/pv';
          }

          window.location.href = route;
        });
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <>
      <Menu as="div" className="ml-0 relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="px-4 h-16 border-gray-200 text-white">
                <span className="sr-only">Open user menu</span>
                <MenuIcon className="h-6 w-6 m-0"></MenuIcon>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-[999] absolute top-16 right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        onClick={item.onClick}
                        to={item.to}
                        className={clsx(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {/* <Menu as="div" className="ml-0 relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="px-4 h-16 border-gray-200 text-white">
                <span className="sr-only">Open user menu</span>
                <BellIcon className="h-6 w-6 m-0"></BellIcon>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg py-1 bg-blue-800 ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Box py={2} textAlign="center">
                  <Heading color="white" size="xs" fontWeight="500">
                    Notifications
                  </Heading>
                </Box>
                <Box maxH={96} maxW="4xl" minW={96} overflow="auto" bg="white">
                  {notificationsData?.map((item) => (
                    <Menu.Item key={item.notification_id}>
                      {({ active }) => (
                        <Link
                          to="#"
                          className={clsx(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                          onClick={() => {
                            setSelectedNotification(item);
                            setNotificationModalOpen(true);
                          }}
                        >
                          <Flex justifyContent="space-between" mb={1}>
                            <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                              {item.author}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              <Moment fromNow unix>
                                {item.notification_unix_time}
                              </Moment>
                            </Text>
                          </Flex>
                          <Text size="xs" fontWeight="semibold" mb={1}>
                            {item.subject}
                          </Text>

                          <Text size="2xs">{item.summary}</Text>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Box>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu> */}
      <VVNotificationModal
        isOpen={notificationModalOpen}
        // setOpen={setAddPatientModalOpen}
        onClose={() => setNotificationModalOpen(false)}
        notification={selectedNotification}
      ></VVNotificationModal>
    </>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 lg:hidden "
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div
            className="relative flex flex-col w-80 bg-gradient-to-b from-blue-900 via-blue-800 to-blue-900"
            css={{ width: '320px !important' }}
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex items-center h-16 flex-shrink-0 m-2  justify-center">
              <Logo />
            </div>
            <Box
              className="flex-1 flex flex-col overflow-y-hidden p-0 w-full"
            // css={{
            //   '&::-webkit-scrollbar': {
            //     width: '4px',
            //   },
            //   '&::-webkit-scrollbar-track': {
            //     width: '6px',
            //   },
            //   '&::-webkit-scrollbar-thumb': {
            //     background: 'white',
            //     borderRadius: '24px',
            //   },
            // }}
            >
              <nav className="flex-1 bg-white space-y-2 w-full h-full">
                <PatientsList />
                {/* <SideNavigation /> */}
              </nav>
            </Box>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

declare global {
  interface Window {
    FC: any;
  }
}

const NavigationBar = ({ setSidebarOpen }: { setSidebarOpen: (state: boolean) => void }) => {
  const {
    isOpen: isHeadsetsOpen,
    onOpen: onHeadsetsOpen,
    onClose: onHeadsetsClose,
  } = useDisclosure();

  const { isCRC } = usePharma();

  // const [loading, error] = useScript({
  //   src: '//cdn.foxycart.com/vividvision/loader.js',
  //   checkForExisting: true,
  //   onload: () => main(),
  // });

  // const [shopData, setShopData]: any = useState<{}>({ test: '' });

  // const main = () => {
  //   fetchCart();
  //   fetchShopData();
  // };

  // const fetchShopData = async () => {
  //   const result = await axios.post<any>('/vue_api/get_shop_items', {});
  //   setShopData(result.data);
  // };

  // // TODO fetchCart isn't doing anything, we should use it or remove it
  // const fetchCart = async () => {
  //   const FC = window.FC;
  // };

  // useEffect(() => {
  //   fetchCart();
  //   fetchShopData();
  // }, []);

  return (
    <>
      <Box className="flex bg-gradient-to-b from-blue-900 via-blue-800 to-blue-900 ">
        <div className="flex flex-col">
          <div className="flex flex-col h-full flex-1">
            <Box className="flex-1 flex flex-col overflow-y-none p-0 w-full ">
              <nav className="flex-1 p-2 w-full mt-24 space-y-2">
                <SideNavigation />
              </nav>
              <Spacer />
              <Box className="p-2 w-full mb-16">
                <Box mb={3}>
                  {isCRC && (
                    <Tooltip label="Manage Headsets" fontSize="md">
                      <Button
                        p="3"
                        h="auto"
                        bg="transparent"
                        color="white"
                        outlineColor="white"
                        outlineOffset={0}
                        _hover={{ background: 'blue.700', color: 'white' }}
                        aria-label="PatientList"
                        onClick={onHeadsetsOpen}
                      // className="text-blue bg-blue-500 hover:bg-blue-700 hover:text-white"
                      // className={'group flex items-center px-3 py-3 text-base font-normal rounded-md'}
                      // activeClassName="text-blue-700 bg-white active:text-white hover:text-white hover:bg-blue-700"
                      >
                        <Box d="relative" textAlign="center">
                          <IconifyIcon
                            icon={VRHeadsetIcon}
                            width="24"
                            height="24"
                            className="flex-shrink-0 -mb-1"
                          />
                          <IconifyIcon
                            icon={VRHeadsetIcon}
                            width="20"
                            height="20"
                            className="flex-shrink-0 absolute -mt-7 ml-[2px] opacity-10"
                          />
                          <IconifyIcon
                            icon={VRHeadsetIcon}
                            width="22"
                            height="22"
                            className="flex-shrink-0 absolute -mt-6  ml-[1px] opacity-30"
                          />
                        </Box>
                        {/* <Icon as={CollectionIcon} h={6} w={6} flexShrink={0} /> */}
                      </Button>
                    </Tooltip>
                  )}
                </Box>
                <Box className="md:hidden">
                  <Tooltip label="Participant List" fontSize="md">
                    <Button
                      p="3"
                      h="auto"
                      bg="transparent"
                      color="white"
                      outlineColor="white"
                      outlineOffset={0}
                      _hover={{ background: 'blue.700', color: 'white' }}
                      aria-label="PatientList"
                      onClick={() => setSidebarOpen(true)}
                    // className="text-blue bg-blue-500 hover:bg-blue-700 hover:text-white"
                    // className={'group flex items-center px-3 py-3 text-base font-normal rounded-md'}
                    // activeClassName="text-blue-700 bg-white active:text-white hover:text-white hover:bg-blue-700"
                    >
                      <Icon as={UserGroupIcon} h={6} w={6} flexShrink={0} />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {/* <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
              <SideNavigation />
            </nav>
          </div> */}
          </div>
        </div>
      </Box>
      <ManageHeadsetsModal isOpen={isHeadsetsOpen} onClose={onHeadsetsClose} />
    </>
  );
};

const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-80">
        <div className="flex flex-col h-0 flex-1">
          <Box
            className="flex-1 flex flex-col overflow-y-none p-0 w-full"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'white',
                borderRadius: '24px',
              },
            }}
          >
            <nav className="flex-1 p-2 bg-white space-y-2 w-full">
              <PatientsList />
            </nav>
          </Box>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to=".">
      <img className="h-10 w-auto" src={logo} alt="Vivid Vision Web" />
      {/* <span className="text-xl text-white font-semibold">Bulletproof React</span> */}
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { isCRC, user: crcUser } = usePharma();

  return (
    <>
      <Head title="Dashboard | Vivid Vision Perimetry" />
      <div className="h-screen flex overflow-hidden bg-gray-200">
        <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <NavigationBar setSidebarOpen={setSidebarOpen} />
        <Box className="hidden flex-col w-80 md:flex md:flex-shrink-0 ">
          <div className="flex flex-col h-0 flex-1">
            <Box className="flex-1 p-0 space-y-0 w-full">
              <PatientsList />
            </Box>
          </div>
        </Box>
        {/* <Sidebar /> */}
        <div className="relative flex flex-col w-0 flex-1 overflow-auto">
          {isCRC && (
            <div className="absolute top-1  w-full flex justify-center z-[999] pointer-events-none">
              <div className="p-2 text-xs z-[999] bg-black text-white rounded-md opacity-50">
                Accessing as CRC user {crcUser?.user_name}
              </div>
            </div>
          )}
          <div className="flex-1 px-0 flex justify-center">
            <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
          </div>
        </div>
        <div className="xs:flex xs:flex-shrink-0 bg-gradient-to-b from-blue-900 via-blue-800 to-blue-900">
          <div className="flex flex-col">
            <div className="flex flex-col h-0 flex-1">
              <Box className="flex-1 flex flex-col overflow-y-none p-0 w-full">
                <nav className="flex-1 space-y-2 w-full">
                  <UserNavigation />
                </nav>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
