// Context for Pharma users
import { createContext, useContext, useEffect, useState } from 'react';
import { UserInfo } from '../features/auth/types';


export type Pharma = {
  user: UserInfo | null;
  plainP: string;
  setPlainP: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  subUsers: { user_id: number; user_name: string }[];
  setSubUsers: React.Dispatch<React.SetStateAction<{ user_id: number; user_name: string }[]>>;
  selectedSubUser: { user_id: number; user_name: string } | null;
  setSelectedSubUser: React.Dispatch<React.SetStateAction<{ user_id: number; user_name: string } | null>>;
  isSiteLoggedIn: boolean;
  setIsSiteLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  flags: string[];
  setFlags: React.Dispatch<React.SetStateAction<string[]>>;
  isPV: boolean;
  setIsPV: React.Dispatch<React.SetStateAction<boolean>>;
  isCRC: boolean;
  setIsCRC: React.Dispatch<React.SetStateAction<boolean>>;
  pharmaLogout: () => void;
};

export const PharmaContext = createContext<Pharma | null>(null);

export const usePharma = () => {
  const context = useContext(PharmaContext);
  if (!context) {
    throw new Error('usePharma must be used within a PharmaProvider');
  }
  return context;
};

export const PharmaProvider: React.FC = ({ children }) => {
  const [user, setUser] = useSessionStorage<UserInfo | null>('pharmaUser', null);
  const [plainP, setPlainP] = useSessionStorage<string>('pharmaPlainP', '');
  const [subUsers, setSubUsers] = useSessionStorage<{ user_id: number; user_name: string }[]>('pharmaSubUsers', []);
  const [selectedSubUser, setSelectedSubUser] = useSessionStorage<{ user_id: number; user_name: string } | null>('pharmaSelectedSubUser', null);
  const [isSiteLoggedIn, setIsSiteLoggedIn] = useSessionStorage<boolean>('pharmaIsSiteLoggedIn', false);

  const [flags, setFlags] = useSessionStorage<string[]>('pharmaFlags', []);
  const [isPV, setIsPV] = useSessionStorage<boolean>('pharmaIsPV', false);
  const [isCRC, setIsCRC] = useSessionStorage<boolean>('pharmaIsCRC', false);

  const pharmaLogout = () => {
    setUser(null);
    setSubUsers([]);
    setFlags([]);
    setIsPV(false);
    setIsCRC(false);
  };


  return (
    <PharmaContext.Provider value={{ user, setUser, plainP, setPlainP, subUsers, selectedSubUser, isSiteLoggedIn, setIsSiteLoggedIn, setSelectedSubUser, setSubUsers, flags, setFlags, isPV, setIsPV, isCRC, setIsCRC, pharmaLogout }}>
      {children}
    </PharmaContext.Provider>
  );
};


function useSessionStorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Get the initial value from session storage or use the provided initialValue
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  // Update session storage whenever the value changes
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}