import { useAuth } from '@/lib/auth';
import { usePharma } from '@/providers/PharmaProvider';
import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { CRCForm } from '../components/CRCForm';

import { Layout } from '../components/Layout';

export const CRCSelect = () => {
  const navigate = useNavigate();
  // const [siteLoginSuccess, setSiteLoginSuccess] = useState(false);
  const auth = useAuth();
  const { setIsSiteLoggedIn, isSiteLoggedIn } = usePharma();


  const { isCRC } = usePharma();

  const handleSiteSelect = () => {
    console.log('Site selected');

    setIsSiteLoggedIn(true);
    navigate('/app/');
  }

  // if (isSiteLoggedIn)
  //   return <Navigate to="/app/" />;

  // // redirect to root if not CRC
  if (!isCRC)
    return <Navigate to="/" />;

  return (
    <Layout title="Log In to a Site | Vivid Vision Perimetry">
      <CRCForm onSuccess={handleSiteSelect} />
    </Layout>
  );
};
