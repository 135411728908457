import { useRoutes } from 'react-router-dom';

import { Login } from '@/features/auth/routes/Login';
import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { lazyImport } from '@/utils/lazyImport';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { usePharma } from '@/providers/PharmaProvider';

export const AppRoutes = () => {
  const auth = useAuth();
  const pharma = usePharma();

  // const commonRoutes = [{ path: '/', element: <Landing /> }];
  const commonRoutes = [{ path: '/', element: <Login /> }];

  if (!auth.user) {
    commonRoutes.push({ path: '*', element: <Login /> });
  }

  if (parseInt(auth.user?.is_mod as string) || parseInt(auth.user?.is_admin as string)) {
    console.log('ModToolsRoutes enabled');
    const { ModToolsRoutes } = lazyImport(() => import('@/features/mod_tools'), 'ModToolsRoutes');
    protectedRoutes[0].children.push({
      path: '/mod_tools/*',
      element: <ModToolsRoutes />,
    });
  }

  let routes = auth.user ? protectedRoutes : publicRoutes;

  // if ((pharma.isCRC || pharma.isPV) && !auth.user) {
  //   routes = publicRoutes;
  // } else {
  //   routes = protectedRoutes;
  // }

  if (pharma.isCRC || pharma.isPV) {
    if (pharma.isSiteLoggedIn) {
      routes = protectedRoutes;
    } else {
      routes = publicRoutes;
    }
  }

  console.log('auth.user?', auth.user);

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
