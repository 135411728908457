import { useAuth } from '@/lib/auth';
import { usePharma } from '@/providers/PharmaProvider';
import { useNavigate, Navigate } from 'react-router-dom';
import { CRCForm } from '../components/CRCForm';

import { Layout } from '../components/Layout';
import { PVForm } from '../components/PVForm';

export const PVSelect = () => {
  const navigate = useNavigate();

  const { isPV } = usePharma();


  // redirect to root if not PV
  // if (!isPV)
  //   navigate('/');


  return (
    <Layout title="Manage Devices | Vivid Vision Perimetry">
      <PVForm />
    </Layout>
  );
};
